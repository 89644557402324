import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter, faInstagram, faInternetExplorer } from '@fortawesome/free-brands-svg-icons'

const Footer = () => {
  return (
    <div>
        <footer class="text-center text-lg-start text-light border-top mt-4 eruvaka-footer">
            <section class="p-1">
                <div class="container text-center text-md-start">
                    <div class="row mt-3">
                        <div class="col-md-3 col-lg-4 col-xl-3 mx-auto">
                            <h6 class="text-uppercase fw-bold mb-1">Eruvaka Agro Products</h6>
                            <p>
                            Here you can use rows and columns to organize your footer content. Lorem ipsum
                            dolor sit amet, consectetur adipisicing elit.
                            </p>
                        </div>

                        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0">
                            <h6 class="text-uppercase fw-bold mb-1">Contact</h6>
                            <p><FontAwesomeIcon icon={faHome} /> Flot No : S2 Industrial Area,Pernamitta,Ongole</p>
                            <p><FontAwesomeIcon icon={faInternetExplorer} /> info@eruvakaagroproducts.com</p>
                            <p><FontAwesomeIcon icon={faPhone} /> 9966352666</p>
                            <p><FontAwesomeIcon icon={faPhone} /> 9963027282</p>
                        </div>

                        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0">
                            <h6 class="text-uppercase fw-bold mb-4">Social</h6>
                            <p><a href="" class="me-4 text-reset"><FontAwesomeIcon icon={faFacebook} /> Facebook</a></p>
                            <p><a href="" class="me-4 text-reset"><FontAwesomeIcon icon={faTwitter} /> Twitter</a></p>
                            <p><a href="" class="me-4 text-reset"><FontAwesomeIcon icon={faInstagram} /> Instagram</a></p>
                        </div>
                    </div>
                </div>
            </section>
            
            <div class="text-center p-3 eruvaka-copyright">
                Copyright © 2022 : Eruvaka Agro Products &nbsp;|&nbsp; Designed & Developed by <a class="text-reset fw-bold" href="http://kkccinfo.com/">KKCCINFO.COM</a>
            </div>
        </footer>
    </div>
  );
}

export default Footer;
