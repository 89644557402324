import React from 'react';
import ProductBanner from './videos/ProductBanner.mp4';
import { Link } from "react-router-dom";
import './ProductCategories.css';
const ProductCategories = () => {
  return (
    <div>
      <video loop autoPlay muted>
            <source src={ProductBanner} type="video/mp4" />
      </video>

      <div className='container-fluid'>

        <div className='row border-bottom p-1'>
          <p><Link to="/" class="navbar-brand text-dark me-3">Home</Link>  / Product Categories</p>
        </div>

        <div className='row p-4 '>
          <div className='col-lg-12'>
            <h4>Product Categories</h4>
            <p>
            A greenhouse is a structure with walls and roof made chiefly of transparent material,
             such as poly film, in which plants requiring regulated climatic conditions are grown.
              These structures range in size from small sheds to industrial-sized buildings.
            </p>
          </div>
          
        </div>
        <div className="d-flex flex-row flex-wrap justify-content-center p-3 m-3">
              <div class="product-cat-img-1 p-3 m-3 ">
                <h3 class="category-header text-center graphik-bold">Products</h3>
              </div>
              <div class="product-cat-img-2 p-3 m-3">
                <h3 class="category-header text-center graphik-bold">Shade Nets</h3>
              </div>
              <div class="product-cat-img-3 p-3 m-3">
                <h3 class="category-header text-center graphik-bold">Polyhouse</h3>
              </div>
              <div class="product-cat-img-4 p-3 m-3">
                <h3 class="category-header text-center graphik-bold">Seedling Trays</h3>
              </div>
              <div class="product-cat-img-1 p-3 m-3 ">
                <h3 class="category-header text-center graphik-bold">Mulching Film</h3>
              </div>
              <div class="product-cat-img-2 p-3 m-3">
                <h3 class="category-header text-center graphik-bold">Cocopet</h3>
              </div>
              <div class="product-cat-img-3 p-3 m-3">
                <h3 class="category-header text-center graphik-bold">Pondliners Sheets</h3>
              </div>
              <div class="product-cat-img-4 p-3 m-3">
                <h3 class="category-header text-center graphik-bold">Hdpe Pipes</h3>
              </div>
        </div>
      </div>
    </div>
  )
}

export default ProductCategories;
