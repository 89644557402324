import React from 'react';
import ContactBanner from './videos/ContactBanner.mp4';
import { Link } from "react-router-dom";
const ReachUs = () => {
  return (
    <div>
      <video loop autoPlay muted>
            <source src={ContactBanner} type="video/mp4" />
      </video>
      <div className='container-fluid'>
        <div className='row p-1 border-bottom'>
          <p><Link to="/" class="navbar-brand text-dark me-3">Home</Link>  / Reach Us</p>
        </div>
      </div>

    </div>
  ); 
}

export default ReachUs;
