import React from 'react';
import BlogBanner from './videos/BlogBanner.mp4';
import Blog1 from './images/blog1.jpg';
import Blog2 from './images/blog2.jpg';
import Blog3 from './images/blog3.jpg';
import Blog4 from './images/blog4.jpg';
import Blog5 from './images/blog5.jpg';
import Blog6 from './images/blog6.jpg';
import { Link } from "react-router-dom";
const Blogs = () => {
  return (
    <div>
      <video loop autoPlay muted>
            <source src={BlogBanner} type="video/mp4" />
      </video>
      <div className='container-fluid'>
        <div className='row p-1 border-bottom'>
          <p><Link to="/" class="navbar-brand text-dark me-3">Home</Link> / Blogs</p>
        </div>
        <div className='row p-4 border-bottom'>
          <div className='col-lg-4'>
          <img src={Blog1} alt="image1"className='img-resize' />
          </div>
          <div className='col-lg-8'>
          <h3>Women farmers emerging as decision-makers, innovators in agro - based systems:</h3>
          <p>Women adopted specific strategies to further their interests in the context of agriculture based livelihoods, challenging men as sole decision-makers.They are transitioning from workers to innovators and managers,
             a recent study has found. Women in societies practicing agriculture have</p>
          </div>
        </div>
        <div className='row p-4 border-bottom'>
          <div className='col-lg-4'>
          <img src={Blog2} alt="image2" className='img-resize' />
          </div>
          <div className='col-lg-8'>
          <h3>Women farmers emerging as decision-makers, innovators in agro - based systems:</h3>
          <p>Women adopted specific strategies to further their interests in the context of agriculture based livelihoods, challenging men as sole decision-makers.They are transitioning from workers to innovators and managers,
             a recent study has found. Women in societies practicing agriculture have</p>
          </div>
        </div>
        <div className='row p-4 border-bottom'>
          <div className='col-lg-4'>
          <img src={Blog3} alt="image3" className='img-resize' />
          </div>
          <div className='col-lg-8'>
          <h3>Women farmers emerging as decision-makers, innovators in agro - based systems:</h3>
          <p>Women adopted specific strategies to further their interests in the context of agriculture based livelihoods, challenging men as sole decision-makers.They are transitioning from workers to innovators and managers,
             a recent study has found. Women in societies practicing agriculture have</p>
          </div>
        </div>

        <div className='row p-4 border-bottom'>
          <div className='col-lg-4'>
          <img src={Blog4} alt="image4" className='img-resize' />
          </div>
          <div className='col-lg-8'>
          <h3>Women farmers emerging as decision-makers, innovators in agro - based systems:</h3>
          <p>Women adopted specific strategies to further their interests in the context of agriculture based livelihoods, challenging men as sole decision-makers.They are transitioning from workers to innovators and managers,
             a recent study has found. Women in societies practicing agriculture have</p>
          </div>
        </div>

        <div className='row p-4 border-bottom'>
          <div className='col-lg-4'>
          <img src={Blog5} alt="image5" className='img-resize' />
          </div>
          <div className='col-lg-8'>
          <h3>Women farmers emerging as decision-makers, innovators in agro - based systems:</h3>
          <p>Women adopted specific strategies to further their interests in the context of agriculture based livelihoods, challenging men as sole decision-makers.They are transitioning from workers to innovators and managers,
             a recent study has found. Women in societies practicing agriculture have</p>
          </div>
        </div>

        <div className='row p-4'>
          <div className='col-lg-4'>
          <img src={Blog6} alt="images6" className='img-resize' />
          </div>
          <div className='col-lg-8'>
          <h3>Women farmers emerging as decision-makers, innovators in agro - based systems:</h3>
          <p>Women adopted specific strategies to further their interests in the context of agriculture based livelihoods, challenging men as sole decision-makers.They are transitioning from workers to innovators and managers,
             a recent study has found. Women in societies practicing agriculture have</p>
          </div>
        </div>
    </div>
    </div>
  )
}

export default Blogs;
