import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './Header';
import Home from './Home';
import Stores from './Stores';
import ProductCategories from './ProductCategories';
import Projects from './Projects';
import OurStory from './OurStory';
import Blogs from './Blogs';
import ReachUs from './ReachUs';
import LogIn from './LogIn';
import Consulting from './Consulting';
import Footer from './Footer';
const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Header/>
      <div className="mt-4">
        <Routes>
            <Route path="/" element={<Home />}  />
            <Route exact path="/stores" element={<Stores />}  />
            <Route exact path="/productcategories" element={<ProductCategories />}  />
            <Route exact path="/consulting" element={<Consulting />}  />
            <Route exact path="/projects" element={<Projects />}  />
            <Route exact path="/ourstory" element={<OurStory />}  />
            <Route exact path="/blogs" element={<Blogs />}  />
            <Route exact path="/reachus" element={<ReachUs />}  />
            <Route exact path="/login" element={<LogIn />}  />
        </Routes>
        </div>
      </BrowserRouter>
      <Footer />
  </div>
  )
}

export default App;
