import React from 'react';
import BgVideo from './videos/ProductBanner.mp4';
import Image1 from './images/1.jpg';
import Image2 from './images/farmer.png';


const Home = () => {
  return (
    <>
    <div>
      <video loop autoPlay muted>
            <source src={BgVideo} type="video/mp4" />
      </video>


      <div className='container-fluid'>

        <div className='row p-5'>
          <div className='col-lg-6 border-end pt-3 pb-3 pe-3'>
            <h1><b>Think Different and  Grow</b></h1>
          </div>
          <div className='col-lg-6 ps-4 pt-3 pb-3'>
            <p>
              With the philosophy of caring for farm and farmers, we offer innovative farming solutions
              that are in line with farmer's aspirations. Our vision is to be the market leader in the field of 
              Hi-tech agriculture in india and leaving footprints in the defined global markets. We keep things 
              simple because we undersatnd how hard this business is.   
            </p>
          </div>
        </div>
        <div className='row border-bottom text-center'>
          <div className='col-md-2 border-start border-end pt-3'>
              <p>Products</p>
          </div>
          <div className='col-md-2 border-end pt-3'>
              <p>Shade Nets</p>
          </div>
          <div className='col-md-2 border-end  pt-3'>
              <p>Polyhouse</p>
          </div>
          <div className='col-md-2 border-end pt-3'>
              <p>Seedling Trays</p>
          </div>
          <div className='col-md-2 border-end pt-3'>
              <p>Mulching Film</p>
          </div>
          <div className='col-md-2 border-end pt-3'>
              <p>Cocopet</p>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-6 p-5 bg-success text-light'>
            <h2><b>Measuring Sustainability</b></h2>
            <p>
              You can only manage what you can measure manage
            </p>
            <p>
              "Develop efficient, self-sufficient and economical production systems that provide
              decent incomes". Preserve and Protect biodiversity and territories. Optiimize the use of 
              natural resources. Increase energy efficiency in food production and distribution 
            </p>
          </div>
          <div className='col-lg-6 p-0'>
              <img src={Image1} className='img-resize' />
          </div> 
        </div>
      </div>
      
      <div className='container-fluid' >





        <div className='row p-3 justify-content-end'>
          <div className='col-lg-6'>
            <p>Want to explore more ?</p>
            <p>Find out to know everything you need to know at a glance about Agriplast.</p>
            <form>
              <div className='row'>
                <div className='col-md-3'>
                  <input type='text' id='name' placeholder='Your Name' />
                </div>
                <div className='col-md-3'>
                  <input type='text' id='num' placeholder='Mobile Number' />
                </div>
              </div>

              <div className='row'>
                <div className='col-md-3'>
                  <input type='text' id='state' placeholder='State' />
                </div>
                <div className='col-md-3'>
                  <input type='text' id='city' placeholder='City' />
                </div>
              </div>

              <div className='row'>
                <div className='col-md-3'>
                 <textarea id='message' placeholder='Message'></textarea>
                </div>
              </div>

              <button type='submit' className='bg-success text-light'>SEND MESSAGE</button>

            </form>
            
          </div>
        </div>
      </div>
    </div>
      
      </>
  );
}

export default Home;
