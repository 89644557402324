import React from 'react'
import ContactBanner from './videos/ContactBanner.mp4';
import { Link } from "react-router-dom";
const OurStory = () => {
  return (
    <div>
    <video loop autoPlay muted>
          <source src={ContactBanner} type="video/mp4" />
    </video>

    <div className='container-fluid'>
      <div className='row p-1 border-bottom'>
        <p><Link to="/" class="navbar-brand text-dark me-3">Home</Link> / OurStory</p>
      </div>

      <div className='row p-5'>
        <div className='col-lg-8'>
          <p className='border-bottom h2 pb-3'><b>Eruvaka Agro Products</b> </p>


        </div>

        <div className='col-lg-4'>

        <form>
            <div className='row'>
              
                <input type='text' id='name' placeholder='Your Name' />
              
              
                <input type='text' id='num' placeholder='Mobile Number' />
              
            </div>

            <div className='row'>
              
                <input type='text' id='state' placeholder='State' />
              
              
                <input type='text' id='city' placeholder='City' />
              
            </div>

            <div className='row'>
              
               <textarea id='message' placeholder='Message'></textarea>
              
            </div>

            <button type='submit' className='bg-success text-light'>SEND MESSAGE</button>

          </form>

        </div>
      </div>
    </div>

  </div>
  )
}

export default OurStory
