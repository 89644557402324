import React from 'react';
import AdvisoryBanner from './videos/AdvisoryBanner.mp4';
import Advisoryimage from './images/advisory1.jpg';
import { Link } from "react-router-dom";
const Advisory = () => {
  return (
    <div>
      <video loop autoPlay muted>
            <source src={AdvisoryBanner} type="video/mp4" />
      </video>

      <div className='container-fluid'>
        <div className='row border-bottom p-1'>
          <p><Link to="/" class="navbar-brand text-dark me-3">Home</Link> / Consulting</p>
        </div>

        <div className='row p-4'>
          <div className='col-lg-6'>
            {/*<img src={Advisoryimage} alt="Consulting" className='img-resize' />*/}
          </div>
          <div className='col-lg-6'>
            <h1>Consulting Service</h1>
            <p>Eruvaka Agro Products Consulting service is a vital element of the array of market
              and non-market entities and agents that provide critical flows of information that can improve farmers 
              and other rural peoples' welfare. After a period of neglect,
              agricultural advisory services have returned strongly to the international development agenda. 
              Apart from their conventional function of providing knowledge for improved agricultural productivity,
              agricultural advisory services are expected to fulfill a variety of new functions, such as linking smallholder farmers to high-value and export markets,
              promoting environmentally sustainable production techniques that affect agriculture.</p>
              <p>
              Eruvaka Agro Products Consulting services have long been recognized as an important factor in promoting agricultural development. Hence it is of utmost importance to acknowledge the role of extension and the challenges of its effective evolution in the coming decades. We at Agriplast Tech India believe that the main objective of Agricultural Advisory is to increase farmers’ access to information, knowledge, and technology for profitable agricultural production.
              </p>
              <p>
              Eruvaka Agro Products Consulting services have long been recognized as an important factor in promoting agricultural development. Hence it is of utmost importance to acknowledge the role of extension and the challenges of its effective evolution in the coming decades. We at Agriplast Tech India believe that the main objective of Agricultural Advisory is to increase farmers’ access to information, knowledge, and technology for profitable agricultural production.
              </p>
              <p>
              Eruvaka Agro Products Consulting services have long been recognized as an important factor in promoting agricultural development. Hence it is of utmost importance to acknowledge the role of extension and the challenges of its effective evolution in the coming decades. We at Agriplast Tech India believe that the main objective of Agricultural Advisory is to increase farmers’ access to information, knowledge, and technology for profitable agricultural production.
              </p>
          </div>
      </div>
    </div>
  </div>
  )
}

export default Advisory
