import React from 'react'
import AdvisoryBanner from './videos/AdvisoryBanner.mp4';
import { Link } from "react-router-dom";
import  './Login.css';
const LogIn = () => {

  return (
    <div>
    <video loop autoPlay muted>
          <source src={AdvisoryBanner} type="video/mp4" />
    </video>
<div className='container-fluid'>
      <div className='row border-bottom p-1'>
        <p><Link to="/" className="navbar-brand text-dark me-3">Home</Link> / Login</p>
      </div>
      <div className='d-flex flex-row justify-content-center'>
     
      <div className='login-div'>
      <form>
        <h1 className="h3 mb-3 fw-normal">Please sign in</h1>

        <div className="form-floating mb-2">
          <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com"/>
          <label htmlFor="floatingInput">Email address</label>
        </div>
        <div className="form-floating mb-2">
          <input type="password" className="form-control" id="floatingPassword" placeholder="Password"/>
          <label htmlFor="floatingPassword">Password</label>
        </div>

        <div className="checkbox mb-3">
          <label>
            <input type="checkbox" value="remember-me"/> Remember me
          </label>
        </div>
        <button className="w-100 btn btn-lg btn-primary" type="submit">Sign in</button>
        
      </form>
      </div>
    </div>
</div>
    
</div>
  )
}

export default LogIn
